import React from 'react';
import { PageBlock } from '@design-system/page-block';

import { DangerouslySetInnerHtml } from 'layout';

import { LdsProps } from './models';

const LdsBlock = ({ ldsContent, pageBlockUI }: LdsProps) => (
  <PageBlock {...pageBlockUI} className="lds-block">
    <DangerouslySetInnerHtml html={ldsContent} />
  </PageBlock>
);

export default LdsBlock;
